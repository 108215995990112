<template>
<!-- TODO : i18n -->
	<div id="bills">
		<div class="row">
			<div class= "list-bills col-md-5">
				<div class="bill-w">
					<h3 class="bill">{{$t("bills.infos")}}</h3>
				</div>
				<div class="id-boss">
					<div class="text"><i>{{ $store.state.domain == "coachs" ? $t("bills.id") : $t("bills.idsalon") }}</i> <span>: {{ $store.state.salon.clientid }}</span></div>
					<div class="text" v-if="$store.state.domain == 'esthetics'"><i>{{ $t("bills.codemulti") }}</i> <span>: {{ $store.state.salon.multicode }}</span></div>
				</div>
				<div class="bill-w">
					<h3 class="bill">{{$t("bills.sub")}}</h3>
				</div>
				<div class="subscription" v-if="$store.state.domain == 'esthetics'">
					<div v-if="subscription == 'multiTODOREMOVEME'" class="d-flex">
						<div class="type-ab-center">
							Multi
						</div>
					</div>
					<template v-else>
						<div class="d-flex">
							<div class="type-ab-right">
								Starter
							</div>
							<ToggleSwitch class="toggle-abonnement" tooltip="none" :value="subscription == 'premium'" @change="updateSubscriptionSave(undefined, $event)"></ToggleSwitch>
							<div class="type-ab-left">
								Premium
							</div>
						</div>
						<div class="online-w">
							<!--div class="online text-muted">
								{{$t("bills.apponline")}}
							</div-->
						</div>
					</template>
				</div>
				<div class="">
					<div class="">
						<div id="payment-toggler-wrapper" class="bill-w">
							<h3 class="bill"><ToggleSwitch ref="paymentShowToggle" v-model="updatePayment" @change="updateSubscriptionSave($event)">{{$t("bills.payment")}}</ToggleSwitch></h3>
						</div>
						<div id="payment-card" :class="{ visible: updatePayment }">
							<div v-if="allowCard" id="method-selector">
								<div>
									<span @click="paymentMethod = 'sepa'; $refs.paymentToggle.setChecked(false); updateSubscriptionSave()">{{$t("bills.levy")}} SEPA</span>
									<ToggleSwitch ref="paymentToggle" @change="paymentMethod = $event ? 'card' : 'sepa'; updateSubscriptionSave()"></ToggleSwitch>
									<span @click="paymentMethod = 'card'; $refs.paymentToggle.setChecked(true); updateSubscriptionSave()">{{$t("bills.card")}}</span>
								</div>
							</div>
							<div id="payment-inner">
								<div v-if="allowCard" v-show="paymentMethod == 'card'" id="card">
									<div ref="cardElement" id="card-element"></div>
								</div>
								<div v-show="!allowCard || paymentMethod == 'sepa'" id="iban">
									<div id="iban-element"></div>
									<span class="iban-bank" v-show="bankName">{{$t("bills.bank")}} : {{ bankName }}</span>
								</div>
							</div>
						</div>
						<StatusButton id="subscription-save" type="primary" :hidden="!showSubscriptionSave" :onclick="saveSubscription">{{ $t('common.save') }}</StatusButton>
					</div>
					<div class="">
						<div class="bill-w">
							<h3 class="bill">{{$t("bills.bills")}}</h3>
						</div>
						<Popper
							class="dropdown d-block years-dropdown"
							trigger="clickToToggle"
							:options="{ placement: 'bottom' }"
							:visible-arrow="false"
							ref="yearsPopper"
						>
							<div class="dropdown-menu" @click="$refs.yearsPopper.doClose()">
								<div class="dropdown-choices">
									<button v-for="year in allYears" class="dropdown-item" @click="yearHistory = 1900 + year.getYear(); updateHistory()">{{ 1900 + year.getYear() }}</button>
								</div>
							</div>
							<button
								slot="reference"
								ref="yearSelector"
								aria-expanded="false"
								aria-haspopup="true"
								class="btn btn-primary dropdown-toggle years"
								data-flip="false"
								data-toggle="dropdown"
							>{{ yearHistory }}</button>
						</Popper>
						<div class="list">
							<div v-for="( bill, i ) in bills" :class="'block-bill' + ( currentBill == i ? ' selected' : '' )" @click="currentBill = i">
								<button class="fas fa-print" @click="print(bill.id)"/>
								<div class="d-inline-block" >{{$t("bills.billof")}} <u>{{ $d(bill.date, "numericonlydate") }}</u></div>
								<div :class="'fas fa-folder' + ( currentBill == i ? '-open' : '' )"></div>
							</div>
						</div>
					</div>
				</div>
				<StatusButton v-if="$store.state.worker.manager" id="termination" type="primary desktop" :speed="terminationTimer" :enabled="terminationTimer <= 3000" @click="terminationModal=$event">{{ $t('administration.contracttermination') }}</StatusButton>
			</div>
			<Bill class="col-md-7" :bill="bills[currentBill]"/>
			<StatusButton v-if="$store.state.worker.manager" id="termination2" type="primary mobile" :speed="terminationTimer" :enabled="terminationTimer <= 3000" @click="terminationModal=$event">{{ $t('administration.contracttermination') }}</StatusButton>
			<Confirm v-if="terminationModal" @close="terminationModal(-1); terminationModal=false" @confirm="cancelSubscription(terminationModal); terminationModal=false">
				{{ $t('bills.cancelconfirm') }}
				<div class="form-group input-group">
					<input class="form-control" autocomplete="new-password" type="password" :placeholder="$t('common.pwd')" v-model="confirmCancelPassword">
				</div>
			</Confirm>
		</div>
	</div>
</template>

<script>
//	import AmostelySignature from '../../fonts/Amostely Signature-normal.js'
//	import OptimaNovaLT from '../../fonts/Optima Nova LT-normal.js'
//	import html2canvas from "html2canvas"
//	import { Base64 } from 'js-base64'
	import dayjs from 'dayjs'
	import VueSlider from 'vue-slider-component'
	import Popper from "vue-popperjs"
	import Bill from "../Bill.vue"
	import Confirm from "../../modals/Confirm.vue"
	import StatusButton from "../../components/StatusButton.vue"
	import ToggleSwitch from "../../components/ToggleSwitch.vue"

	export default {
		components: {
			VueSlider,
			Popper,
			Bill,
			Confirm,
			StatusButton,
			ToggleSwitch
		},
		data() {
			return {
				dayjs,
				yearHistory: 1900 + new Date().getYear(),
				bills: [
				],
				currentBill: 0,
				terminationModal: false,

				subscription: this.$store.state.salon.subscription,
				showSubscriptionSave: false,

				priceIds: {},
				updatePayment: false,
				paymentMethod: "sepa",
				publishableKey: ( ( process.env.VUE_APP_MODE == "production" || process.env.NODE_ENV == "production" ) ? "pk_live_lKi0dIA88vRSusRYXqcqUibd00M3t1Kjw8" : "pk_test_51GuguAFgwfwbC4ihVu5gBTQl2hpl5V3LNdQ1yYfGi81cgwUpgG9bs8Suv0D9NFTlV0PDYqHYFuhEMmTYFPxsAtfP007KcZzXjn" ),
				locale: "fr",
				bankName: "",
				paymentMethodId: null,
				stripeError: "",
				gaiaError: "",
				allowCard: true, // TEST

				terminationTimer: 3000,
				confirmCancelPassword: null,
			}
		},
		computed: {
			allYears() {
				let ret = []
				let date = new Date( this.$store.state.salon.subscription_date )
				while ( date.getYear() <= new Date().getYear() ) {
					ret.push( date )
					date = date.addYears( 1 )
				}
				return ret
			},
		},
		methods: {
			updateSubscriptionSave( en, subscription ) {
				this.subscription = ([ "starter", "premium" ])[ +subscription ]
				console.log( this.subscription )
				this.showSubscriptionSave = en || ( this.subscription != this.$store.state.salon.subscription )
			},
			print( id ) {
				const win = window.open( process.env.VUE_APP_API_ROOT + "/invoices/" + id + "?type=pdf&token=" + this.$api.headers["X-Access-Token"] )
				/*
				this.$api.get( "/invoices/" + id + "?type=pdf" ).then( response => {
					console.log( "data:application/pdf;base64, " + Base64.encode(response.data) )
					window.open( "data:application/pdf;base64, " + Base64.encode(response.data) )
				}).catch( error => {
					console.log( error )
				})
				*/
			},
			reloadStripe() {
				let e = document.createElement( 'script' )
				e.src = "https://js.stripe.com/v3"
				e.type = 'text/javascript'
				document.getElementsByTagName('head')[0].appendChild( e )
				e.addEventListener( 'load', () => {
					const options = {
					//	stripeAccount: this.stripeAccount,
						locale: this.locale,
					}
					this.stripe = window.Stripe( this.publishableKey, options )
					this.elements = this.stripe.elements()

					if ( this.allowCard ) {
						this.card = this.elements.create( 'card', { style: this.style } )
						this.card.mount( '#card-element' )
						this.card.addEventListener('change', ({ error }) => {
							this.stripeError = error ? error.message : ""
						})
					}

					this.iban = this.elements.create( 'iban', { style: this.style, supportedCountries: ['SEPA'] } )
					this.iban.mount( '#iban-element' )
					this.iban.addEventListener('change', ( event ) => {
						this.stripeError = event.error ? event.error.message : ""
						this.bankName = event.bankName || ""
					})
/*
					if ( this.allowCard ) {
						this.clearCardStyle()
					}
					this.clearIbanStyle()
*/
				})
			},
			async createPaymentMethod( source ) {
				console.log( "createPaymentMethod", source )
				let ret = null
				await this.stripe.createPaymentMethod( source ).then( result => {
					if ( result.error ) {
						this.stripeError = result.error.message
						this.submitCallback( false )
					} else {
						ret = result.paymentMethod.id
					}
				})
				return ret
			},
			async saveSubscription( callback ) {
				try {
					this.stripeError = ""
					this.gaiaError = ""
					const priceId = this.priceIds[ this.subscription ]
					if ( this.updatePayment ) {
						if ( !this.paymentMethodId ) {
							console.log( "method", this.method )
							if ( this.paymentMethod == "sepa" ) {
								this.paymentMethodId = await this.createPaymentMethod( { type: "sepa_debit", sepa_debit: this.iban } )
							} else if ( this.paymentMethod == "card" ) {
								this.paymentMethodId = await this.createPaymentMethod( { type: "card", card: this.card } )
							}
						}
					}
					let data = {}
					if ( this.paymentMethodId ) {
						data.paymentMethodId = this.paymentMethodId
					}
					if ( this.subscription != this.$store.state.salon.subscription ) {
						data.priceId = priceId
					}
					const ret = await this.$api.salon.patch( this.$store.state.salon.id, data )
					callback( true )
					this.updatePayment = false
					setTimeout( () => {
						this.showSubscriptionSave = false
					}, 750 )
				} catch ( error ) {
					console.log( error )
					callback( false, $t( "errors." + ( error.response ? error.response.data.error : error.message ) ) )
				}
			},
			async cancelSubscription( callback ) {
				try {
					const ret = await this.$api.post( `/esthetics/${this.$store.state.salon.id}/unsubscribe`, { password: this.confirmCancelPassword } )
					this.terminationTimer = 99999999999
					this.$nextTick( () => {
						callback( true, this.$t("bills.cancelsuccess") )
					})
				} catch ( error ) {
					console.log( error )
					callback( false, this.$t( "errors." + ( error.response ? error.response.data.error : error.message ) ) )
				}
			}
		},
		mounted() {
			this.$api.bills.get().then( response => {
				this.bills = response.data
				this.bills.forEach( bill => {
					bill.date = new Date(bill.date)
				})
			}).catch( error => {
				console.log( error )
			})

			this.$nextTick( () => {
				this.reloadStripe()
			})

			this.$api.salons.watch( event => {
				if ( event.method == "PATCH" && event.data[0].subscription ) {
					this.subscription = event.data[0].subscription
				}
			})

			this.$api.get( `/public/${this.$store.state.domain}/stripeprices` ).then( response => {
				this.priceIds = response.data
			}).catch( error => {
				console.log( error )
			})
		}
	}
</script>

