<template>
	<div :class="`checkbox checkbox-${type}`" @keypress="$event.preventDefault()" @keyup.space="nowChecked = !nowChecked; $emit('change', nowChecked)" @keyup.enter="nowChecked = !nowChecked; $emit('change', nowChecked)">
		<input :id="`checkbox-${_uid}`" :checked="nowChecked" class="styled d-none" type="checkbox" @change="nowChecked = $event.target.checked; $emit('change', nowChecked)">
		<label :for="`checkbox-${_uid}`" tabindex="0" class="d-inline-block">
			<div class="checkbox-text"><slot/></div>
		</label>
	</div>
</template>


<script>
	export default {
		props: [
			"type",
			"checked"
		],
		watch: {
			checked( value ) {
				this.nowChecked = value
			}
		},
		data() {
			return {
				nowChecked: this.checked
			}
		}
	}
</script>
