<template>
	<div>
		<div v-if="isDemo" id="demo-overlay"></div>
		<div v-if="isDemo" id="demo-message">Ces fonctionnalités ne sont pas disponibles en démo</br><i>Cette page paramètre la vitrine du salon visible par les clients sur l'application Espace-Temps</i></div>
		<div class="card">
			<form id="settings" class="form" @submit.prevent="">
				<!--div>
					<ToggleSwitch :value="$store.state.salon.multiple_promote" @change="multiplePromote = $event; showSave = true">Mises en avant multiples</ToggleSwitch>
					<div class="config-description text-muted">Active les fonctionnalités de "mises en avant" de plusieurs produits / promotions sur le site web</div>
				</div-->
				<!--div v-if="visibleOnline">
					<ToggleSwitch :value="$store.state.salon.prevent_online" v-model="preventOnline" @change="showSave=true">{{$t("administration.closure")}}</ToggleSwitch>
					<div class="config-description text-muted">{{$t("administration.anticipated")}}</div>
				</div-->
				<div>
					<ul class="form-App">
						<li>
							<span class="step">{{$t("administration.infos")}}</span>
							<div id="page-link">Lien de la page : <a :href="generatedPageUrl" target="_blank">{{ generatedPageUrl }}</a></div>
							<div class="carousel-pic2 row">
								<div class="col-md-4" v-for="i in 3" :key="i">
									<img :ref="'carouselImage' + ( i - 1 )" class="img-carousel" :src="carouselImages[i - 1].img || '/img/placeholder.jpg'"/>
									<div class="edit">
										<button class="btn btn-primary" @click="$refs['carouselInput' + (i - 1)][0].click()"><i class="fa fa-edit"></i></button>
										<button class="btn btn-secondary" @click="carouselChanged( i - 1, $event, true ); showSave=true"><i class="fa fa-trash"></i></button>
									</div>
									<input class="d-none" :ref="'carouselInput' + ( i - 1 )" type="file" @change="carouselChanged( i - 1, $event ); showSave=true" />
								</div>
							</div>
							<div v-if="$store.state.domain == 'coachs'" class= "row block1">
								<div id="description-block" class="col-md-12">
									<span class="step subtitle">{{$t("common.descript")}}</span>
									<div class="description-wrapper">
										<div class="input-group form-group description">
											<Vueditor ref="description"/>
										</div>
										<div class="d-flex">
											<div class="count-left" :class="{ yellow: 1000 - description_length < 60, red: 1000 - description_length < 28 }">
												{{ $tc( "sms.charleft", 1000 - description_length, { max: 1000 } ) }}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class= "row block1">
								<div class="address-block col-md-6">
									<span class="step subtitle">{{$t("administration.contactinfos")}}</span>
									<div class="input-group form-group address">
										<div class="input-group-prepend">
											<span class="input-group-text">{{$t("administration.address1")}}</span>
										</div>
										<input class="form-control" type="text" v-model="contact.address1" @input="addressChanged(); showSave=true">
									</div>
									<div class="input-group form-group address2">
										<div class="input-group-prepend">
											<span class="input-group-text">{{$t("administration.address2")}}</span>
										</div>
										<input class="form-control" type="text" v-model="contact.address2" @input="addressChanged(); showSave=true">
									</div>
									<div class="input-group form-group">
										<div class="input-group-prepend">
											<span class="input-group-text">{{$t("common.phone")}}</span>
										</div>
										<input class="form-control" type="text" v-model="contact.phone" @input="addressChanged(); showSave=true">
									</div>
									<div class="input-group form-group">
										<div class="input-group-prepend">
											<span class="input-group-text">{{$t("common.email")}}</span>
										</div>
										<input class="form-control" type="text" v-model="contact.email" @input="addressChanged(); showSave=true">
									</div>
									<div class="input-group form-group">
										<div class="input-group-prepend">
											<span class="input-group-text"><i class="fab fa-youtube"/>Youtube</span>
										</div>
										<input class="form-control" type="text" v-model="contact.socials.youtube" @input="addressChanged(); showSave=true">
									</div>
									<div class="input-group form-group">
										<div class="input-group-prepend">
											<span class="input-group-text"><i class="fab fa-facebook"/>Facebook</span>
										</div>
										<input class="form-control" type="text" v-model="contact.socials.facebook" @input="addressChanged(); showSave=true">
									</div>
									<div id="instagram-group" class="input-group form-group">
										<div class="input-group-prepend">
											<span class="input-group-text"><i class="fab fa-instagram"/>Instagram</span>
										</div>
										<div class="input-wrapper">
											<!--div class="at">@</div-->
											<input class="form-control" type="text" v-model="contact.socials.instagram" @input="addressChanged(); showSave=true">
										</div>
										<!--div id="insta-connect" :class="{ visible: !!contact.socials.instagram }">
											<StatusButton type="primary" @click="instagramConnect">Connecter à Instagram</StatusButton>
											<button class="btn btn-secondary">?</button>
										</div-->
									</div>
								</div>
								<!--div id="map-block" class="col-md-6" :class="'domain-' + $store.state.domain">
									<span class="step subtitle">Géolocalisation</span>
									<l-map id="map" ref="map" :zoom="4" :options="{ doubleClickZoom: false }" @dblclick="setMarker($event)">
										<l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
										<l-circle v-if="$store.state.domain == 'coachs' && mapMarker && scope > 0" :lat-lng="mapMarker" :radius="scope * 1000" />
										<l-marker v-if="mapMarker" :lat-lng="mapMarker"/>
										<l-control-attribution position="bottomright" class="vue" prefix="Vue"></l-control-attribution>
										<l-control-attribution position="bottomright" prefix="Données <a href='https://www.openstreetmap.fr/' target='_blank'>OpenStreetMap</a>"></l-control-attribution>
										<l-control-attribution position="bottomright" :prefix="$t('administration.locationservicemsg')"></l-control-attribution>
									</l-map>
									<div id="map-buttons">
									<
										<StatusButton id="from-address" type="primary" @click="mapFromAddress">{{ $t("administration.fromaddress") }}</StatusButton>
										<span>{{ $t("administration.dblclickplace") }}</span>
										<button id="undo" class="btn btn-primary" @click="mapUndo"><i class="fa fa-undo"></i></button>
									>
									</div>
									<div v-if="$store.state.domain == 'coachs'" id="scope-block">
										<div class="scop input-group form-group">
											<div class="input-group-prepend">
												<span class="input-group-text">{{$t("administration.scope")}}</span>
											</div>
											<input class="form-control" type="number" v-model="scope" @input="scopeChanged(); showSave=true">
											<div class="unit">km</div>
										</div>
									</div>
								</div-->
								<!--<div class="col-md-4">
									<div class="schedules-block">
										<span class="step subtitle">{{$t("administration.schedules")}}</span>
										<div class="child2 schedule mobile-100">
											<div v-for="( day, i ) in mainSchedule" :key="i" class="checkbox checkbox-primary">
												<input :checked="day !== undefined" class=" styled d-none" type="checkbox">
												<label class="d-inline-block day" for="_">
													<div class="checkbox-text">{{ $t(`time.shortweekdays[${i}]`) }}</div>
												</label>
												<vue-markdown class="d-inline-block hours">{{ day }}</vue-markdown>
											</div>
										</div>
									</div>
								</div>-->
							</div>
						</li>
						<li v-if="$store.state.domain == 'coachs'">
							<span class="step">{{$t("Lieux des séances")}}</span>
							<div id="where">
								<CheckBox type="primary" :checked="where.client" @change="where.client = $event; showSave = true">Chez le/la coaché(e)</CheckBox>
								<CheckBox type="primary" :checked="where.coach" @change="where.coach = $event; showSave = true">Chez vous</CheckBox>
								<CheckBox type="primary" :checked="where.outside" @change="where.outside = $event; showSave = true">En extérieur</CheckBox>
								<CheckBox type="primary" :checked="where.online" @change="where.online = $event; showSave = true">En visio</CheckBox>
							</div>
						</li>
						<li>
							<span class="step">{{$t("administration.services")}}</span><!--span class="servonapp">{{ $t('administration.servonapp') }}</span-->
							<div id="prices-selector">
								<div class="radio radio-primary">
									<input type="radio" id="rlowest" v-model="serviceprices" value="lowest" @click="showSave=true">
									<label for="rlowest">{{ $t('administration.lowest') }}</label>
								</div>
								<div class="radio radio-primary">
									<input type="radio" id="rhighest" v-model="serviceprices" value="highest" @click="showSave=true">
									<label for="rhighest">{{ $t('administration.highest') }}</label>
								</div>
								<div v-if="$store.state.domain == 'esthetics'" class="radio radio-primary">
									<input type="radio" id="rrange" v-model="serviceprices" value="range" @click="showSave=true">
									<label for="rrange">{{ $t('administration.range') }}</label>
								</div>
								<div class="radio radio-primary">
									<input type="radio" id="rnoprices" v-model="serviceprices" value="off" @click="showSave=true">
									<label for="rnoprices">{{ $t('administration.noprices') }}</label>
								</div>
							</div>
							<div class="row services">
								<div
									:class="Object.keys(servicesSteps).length === 4 ? 'col-md-3' : 'col-md-4'"
									v-for="( stepText, step ) in servicesSteps"
								>
									<div class="step subtitle text-center">{{ stepText != -1 ? stepText : "" }}</div>
									<CheckBox v-for="( service, i ) in $store.state.services" v-if="stepText == -1 || (service.step == step && service.type > 0)" :key="service.id" type="primary" :checked="service.show_website" @change="servicesChanges[service.id] = $event; showSave = true">
										<vue-markdown>{{ service.title }}</vue-markdown>
										<div class="spacer" v-if="serviceprices !== 'off'"></div>
										<div class="price" v-if="serviceprices === 'lowest'">{{ edgeOfService(service, Math.min) }}€</div>
										<div class="price" v-if="serviceprices === 'highest'">{{ edgeOfService(service, Math.max) }}€</div>
										<div class="price" v-else-if="serviceprices === 'range'">{{ rangeOfService(service) }}</div>
									</CheckBox>
									<div class="no-data-hint" v-if="stepText != -1 && !$store.state.services.filter(service => service.step == step && service.type > 0).length">
										{{ $t('administration.websitenoprestation') }}
									</div>
								</div>
							</div>
							<!--div class="row pack-block">
								<div class="col-md-8">
									<div>
										<span class="step subtitle">{{$t("administration.packages")}}</span>
										<div class="packages">
											<CheckBox v-for="formula in formulas" :key="formula.id" type="primary" :checked="formula.show_website" @change="formulasChanges[formula.id] = $event; showSave = true">
												<vue-markdown>{{ formula.title || formulaTitle( formula ) }}</vue-markdown>
												<div class="spacer" v-if="serviceprices !== 'off'"></div>
												<div class="price" v-if="serviceprices === 'lowest'">{{ edgeOfPackage(formula, Math.min) }}</div>
												<div class="price" v-if="serviceprices === 'highest'">{{ edgeOfPackage(formula, Math.max) }}</div>
												<div class="price" v-else-if="serviceprices === 'range'">{{ rangeOfPackage(formula) }}</div>
											</CheckBox>
										</div>
										<div class="no-data-hint" v-if="!formulas.length">
											{{ $t('administration.websitenopack') }}
										</div>
									</div>
								</div>
								<div v-if="$store.state.domain == 'esthetics'" class="col-md-4">
									<div class="step subtitle">{{ $t("administration.technic") }}</div>
									<div class="tech">
										<CheckBox v-for="( service, i ) in $store.state.services" v-if="service.type < 0" :key="service.id" type="primary" :checked="service.show_website" @change="servicesChanges[service.id] = $event; showSave = true">
											<vue-markdown>{{ service.title }}</vue-markdown>
											<div class="spacer" v-if="service.only_quote || serviceprices !== 'off'"></div>
											<div v-if="service.only_quote" class="price text-muted">{{ $t('common.quote') }}</div>
											<template v-else>
												<div class="price" v-if="serviceprices === 'lowest'">{{ edgeOfService(service, Math.min) }}€</div>
												<div class="price" v-if="serviceprices === 'highest'">{{ edgeOfService(service, Math.max) }}€</div>
												<div class="price" v-else-if="serviceprices === 'range'">{{ rangeOfService(service) }}</div>
											</template>
										</CheckBox>
										<div class="no-data-hint" v-if="!$store.state.services.filter(service => service.type < 0).length">
											{{ $t('administration.websitenoprestation') }}
										</div>
									</div>
								</div>
							</div-->
						</li>
						<li v-if="$store.state.domain == 'esthetics'" class="block2">
							<span class="step">{{$t("administration.gallery")}}</span>
							<div class="gallery-section">
								<Draggable v-model="galleryImages" :scroll-sensitivity="100" :force-fallback="true" @sort="showSave=true" class="ui-sortable" handle=".picture-wrap" :move="isDraggable">
									<div v-for="( image, i ) in galleryImages.filter( i => !i.delete )" class="picture-wrap">
										<img :ref="'galleryImage' + i" class="picture" :src="image.image" />
										<button class="btn btn-quaternary" @click="galleryRemove( image ); showSave=true"><i class="fa fa-trash"></i></button>
									</div>
									<div class="input-group-append">
										<button id="browse2" class="input-group-text btn btn-secondary galerie"  @click="$refs.galleryInput.click()">
											<i class="fas fa-plus"/>
										</button>
										<input class="d-none" ref="galleryInput" type="file" @change="galleryAdd( $event ); showSave=true" />
									</div>
								</Draggable>
								<!--div class="input-group-append">
									<button id="browse2" class="input-group-text btn btn-secondary galerie"  @click="$refs.galleryInput.click()"><i class="fas fa-plus"/></button>
									<input class="d-none" ref="galleryInput" type="file" @change="galleryAdd( $event ); showSave=true" />
								</div-->
							</div>
							<!--StatusButton ref="save" id="save-settings" type="primary" :onclick="save">{{ $t('common.save') }}</StatusButton TEST -->
						</li>
						<li class="block1 block3" v-if="$store.getters.salonPrestationsHasReviews()">
							<span class="step">{{$t("administration.reviewsandpromote")}}</span>
							<i><router-link to="/dashboard" class="seedashboard"> ({{$t("administration.seedashboard")}})</router-link></i>
						</li>
					</ul>
					<div id="save-helper" :class="showSaveHelper && showSave ? 'visible' : ''">
						<button class="btn btn-tertiary" @click="$scrollTo( $refs.save.$el, 500 )"><span class="desktop">{{ $t('common.save') }}</span></button>
					</div>
				</div>
				<StatusButton ref="save" id="save-settings" type="primary" :hidden="!showSave" :onclick="save">{{ $t('common.save') }}</StatusButton>
			</form>
		</div>
		<!--Modal v-if="instagramConnect" type="small" name="instagram-connect" @close="instagramConnect=false">
			<template v-slot:header>
				<h1 class="title-search">Instagram</h1>
			</template>
			<template v-slot:content>
				<div id="insta-text">INSTAGRAM O-AUTH FORM HERE</div>
			</template>
			<template v-slot:buttons>
				<button id="cancel" class="btn btn-gray" @click="instagramConnect=false" type="button">Close</button>
			</template>
		</Modal-->
		<iframe id="instagram-form" v-if="instagramFormURL" :src="instagramFormURL">
		</iframe>
	</div>
</template>

<script>
	import removeAccents from 'remove-accents'
	import axios from 'axios'
	import VueMarkdown from 'vue-markdown'
	import Draggable from 'vuedraggable'
	import L, { latLng } from "leaflet"
	import { LMap, LTileLayer, LMarker, LCircle, LIcon, LControlAttribution } from 'vue2-leaflet'
	import ToggleSwitch from '../../components/ToggleSwitch.vue'
	import StatusButton from "../../components/StatusButton.vue"
	import CheckBox from "../../components/CheckBox.vue"
	import Modal from "../../components/Modal.vue"
	import 'leaflet/dist/leaflet.css'
	import { computeServices } from '../../servicesLogic.js'

	export default {
		components: {
			LMap,
			LTileLayer,
			LCircle,
			LMarker,
			LControlAttribution,
			VueMarkdown,
			Draggable,
			Modal,
			ToggleSwitch,
			StatusButton,
			CheckBox
		},
		data() {
			return {
				socialsBeforeEdit: {},
				description: this.$store.state.salon.description,
				description_length: 0,
				scope: 0,
				where: this.$store.state.domain == "coachs" ? {
					client: ( this.$store.state.salon.where || [] ).includes("client"),
					coach: ( this.$store.state.salon.where || [] ).includes("coach"),
					outside: ( this.$store.state.salon.where || [] ).includes("outside"),
					online: ( this.$store.state.salon.where || [] ).includes("online"),
				} : null,
				contact: {
					address1: this.$store.state.salon.address1,
					address2: this.$store.state.salon.address2,
					phone: this.$store.getters.getGeneralWorker().phone,
					email: this.$store.getters.getGeneralWorker().email,
					socials: {}
				},
				mainSchedule: [],
				servicesChanges: [],
				formulasChanges: [],
				multiplePromote: undefined,
				requestOnline: this.$store.state.salon.request_online,
				preventOnline: this.$store.state.salon.prevent_online,
				showSaveHelper: false,
				showSave: false,
				serviceprices: this.$store.state.salon.prices_mode || 'off',
				carouselImages: [ { id: -1, img: "" }, { id: -1, img: "" }, { id: -1, img: "" } ],
				galleryImages: [],
				mapMarker: null,
				addressChangedTimeout: null,
				instagramFormURL: null
			}
		},
		computed: {
			isDemo() {
				return process.env.VUE_APP_DEMO
			},
			generatedPageUrl() {
				let ret = ""
				if ( this.$store.state.domain == "esthetics" ) {
					ret = "https://espacetemps.io/salon/"
				} else if ( this.$store.state.domain == "coachs" ) {
					ret = "https://app.mentorapp.fr/coach/"
				}
				return ret + this.$store.state.salon.id + "-" + removeAccents(this.$store.state.salon.name).replace( /[^a-zA-Z0-9\-]/gi, "-" ).toLowerCase()
			},
			servicesSteps() {
				if ( this.$store.state.domain == "coachs" ) {
					return { "-1": -1 }
				}
				return this.$store.getters.salonTypes().map( type => this.$t('data.servicesstep')[type] ).reduce( (a, b) => ({ ...a, ...b }), {} )
			},
			formulas() {
				let formulas = this.$store.getters.getFormulas()
				formulas = formulas.sort( ( a, b ) => a.id - b.id )
				for ( let i = 0; i < formulas.length; i++ ) {
					let formula = formulas[i]
					if ( formula.services ) {
						formula.computedServices = computeServices( this.$store.state.services, formula.services )
						formula.computedServices = formula.computedServices.sort( ( a, b ) => a.priority > b.priority )
						if ( formula.title == null || formula.title.length == 0 ) {
							let title = []
							for ( let j = 0; j < formula.computedServices.length; j++ ) {
								title.push( formula.computedServices[j].title )
							}
							formula.titleComputed = title.join( " - " )
						}
					}
				}
				console.log( "formulas", formulas )
				return formulas
			}
		},
		watch: {
			requestOnline( value ) {
				if ( value && !this.$store.state.salon.request_online ) {
					// TODO : show modal for online registration
				}
			}
		},
		methods: {
			update() {
				const links = {
					facebook: [ "facebook.com", "fb.me" ],
					instagram: [ "instagram.com", "instagr.am" ],
					messenger: [ "messenger.com" ],
					tiktok: [ "tiktok.com" ],
					wechat: [ "wechat.com" ],
					tumblr: [ "tumblr.com" ],
					snapchat: [ "snapchat.com" ],
					pinterest: [ "pinterest.com", "pinterest.fr" ],
					whatsapp: [ "whatsapp.com" ],
					youtube: [ "youtube.com" ],
					twitter: [ "twitter.com" ]
				}
				;( this.$store.state.salon.socials || [] ).forEach( url => {
					Object.entries(links).forEach( ([media, link]) => {
						for ( let j = 0; j < link.length; j++ ) {
							const reg = new RegExp( "^https?:\/\/[a-zA-Z0-9\-]*\.*" + link[j] )
							if ( url.match( reg ) ) {
								this.contact.socials[media] = url
								this.socialsBeforeEdit[media] = url
							}
						}
					})
				})
				this.$nextTick(() => {
					this.$refs.description.setContent( this.$store.state.salon.description )
				})
			},
			setMarker(event) {
				/*
				this.mapMarker = event.latlng
				this.showSave = true
				this.showSaveHelper = true
				*/
			},
			addressChanged() {
				if ( this.addressChangedTimeout ) {
					clearTimeout( this.addressChangedTimeout )
					this.addressChangedTimeout = null
				}
				this.addressChangedTimeout = setTimeout( () => {
					this.mapFromAddress()
				}, 800 )
			},
			scopeChanged() {
			},
			async mapFromAddress( callback ) {
				try {
				//	const resp = await axios.get( "https://nominatim.openstreetmap.org/search", { params: { format: "json", q: this.contact.address1 + " " + this.contact.address2 } } )
					const resp = await axios.get( "https://api-adresse.data.gouv.fr/search", { params: { q: this.contact.address1 + " " + this.contact.address2 } } )
				//	if ( resp.data && resp.data[0] ) {
					if ( resp.data ) {
					//	this.mapMarker = latLng( resp.data[0].lat, resp.data[0].lon )
						this.mapMarker = latLng( resp.data.features[0].geometry.coordinates[1], resp.data.features[0].geometry.coordinates[0] )
						this.$refs.map.mapObject.setView( this.mapMarker, 15 )
						this.showSave = true
						this.showSaveHelper = true
						if ( callback ) callback( true )
					} else {
						if ( callback ) callback( -1 )
					}
				} catch( error ) {
					console.log( error )
					if ( callback ) callback( false, error.message )
				}
			},
			async mapUndo() {
				this.mapMarker = this.mapMarkerBase
				this.$refs.map.mapObject.setView( this.mapMarker, 15 )
			},
			carouselChanged( i, event, deleted ) {
				console.log( "carouselChanged", i, event, deleted )
				let self = this
				let input = event.target
				if ( deleted ) {
					self.$refs["carouselImage" + i][0].setAttribute( "data-deleted", true )
					self.$refs["carouselImage" + i][0].setAttribute( "src", "/img/placeholder.jpg" )
					this.carouselImages[i].id = -1
					this.carouselImages[i].img = ""
					this.$forceUpdate()
				} else if ( input && input.files && input.files[0] ) {
					let reader = new FileReader()
					reader.onload = function(e) {
						self.$refs["carouselImage" + i][0].setAttribute( "data-deleted", false )
						self.$refs["carouselImage" + i][0].setAttribute( "src", e.target.result )
					}
					reader.readAsDataURL( input.files[0] )
				}
			},
			galleryAdd( event ) {
				let self = this
				let input = event.target
				if ( input && input.files && input.files[0] ) {
					let image = {
						file: input.files[0]
					}
					this.galleryImages.push( image )
					let reader = new FileReader()
					reader.onload = function(e) {
						image.image = e.target.result
						self.$forceUpdate()
					}
					reader.readAsDataURL( input.files[0] )
				}
			},
			galleryRemove( image ) {
				console.log( "galleryRemove", image )
				if ( image.file ) { // This means that it has been selected in the current session and still not uploaded
					this.galleryImages = this.galleryImages.filter( i => i != image )
				} else {
					image.delete = true
				}
			},
			isDraggable( e ) {
				console.log( e.draggedContext.index, e.draggedContext.futureIndex, this.galleryImages.length - 1 )
				return e.draggedContext.futureIndex < this.galleryImages.length - 1
			},
			imageResize( imgBase ) {
				return new Promise( ( resolve, reject ) => {
					let img = document.createElement( "img" )
					img.src = ( typeof imgBase == "string" ? imgBase : imgBase.src )
					const MAX_WIDTH = 1280
					const MAX_HEIGHT = 800
					let width = img.width
					let height = img.height

					if ( width > height ) {
						if ( width > MAX_WIDTH ) {
							height *= MAX_WIDTH / width
							width = MAX_WIDTH
						}
					} else {
						if (height > MAX_HEIGHT ) {
							width *= MAX_HEIGHT / height
							height = MAX_HEIGHT
						}
					}
					let canvas = document.createElement( "canvas" )
					canvas.width = width
					canvas.height = height
					let ctx = canvas.getContext( "2d" )
					ctx.drawImage( img, 0, 0, width, height )
					canvas.toBlob( blob => {
						resolve( blob )
					}, "image/jpeg" )
				})
			},
			async carouselSave() {
				let formData = new FormData()
				let order = []
				for ( let i = 0; i < 3; i++ ) {
					let file = this.$refs["carouselInput" + i][0].files[0]
					if ( this.$refs["carouselImage" + i][0].getAttribute( "data-deleted" ) == "true" ) {
						console.log( "Carousel image", i, "deleted" )
						order.push( "delete" )
					} else if ( file ) {
						console.log( "Carousel image", i, "set to", file )
						formData.append( "photos", await this.imageResize(this.$refs["carouselImage" + i][0]), file.name )
						order.push( file.name )
					} else {
						console.log( "Carousel image", i, ": nothing" )
						order.push( "" )
					}
				}
				formData.append( "order", JSON.stringify(order) )
				return await this.$api.post( `/${this.$store.state.domain}/salons/${this.$store.state.salon.id}/carousel`, formData )
			},
			async gallerySave() {
				let formData = new FormData()
				let order = []
				console.log( this.galleryImages )
				for ( let i = 0; i < this.galleryImages.length; i++ ) {
					const image = this.galleryImages[i]
					console.log( image )
					order.push({
						filename: image.file ? image.file.name : ( image.image.match( /^.*\/(.*\/.*)$/ )[1] ),
						upload: !!image.file,
						delete: image.delete
					})
					if ( !image.delete ) {
						if ( image.file ) {
							formData.append( "photos", await this.imageResize(image.image), image.file.name )
						}
					}
				}
				formData.append( "order", JSON.stringify(order) )
				return await this.$api.post( `/${this.$store.state.domain}/salons/${this.$store.state.salon.id}/gallery`, formData )
			},
			formulaTitle( formula ) {
				let ret = []
				let services = []
				for ( let i = 0; i < formula.services.length; i++ ) {
					services.push( this.$store.getters.getService( formula.services[i] ) )
				}
				services.sort( ( a, b ) => a.priority - b.priority ).forEach( service => ret.push( service.title ) )
				return ret.join( " + " )
			},
			edgeOfService( service, comp, force_gender ) {
				let all = []
				const gender_hair = {
					female: [ 'short', 'half', 'long' ],
					male: [ 'short', 'long' ],
					child: [ null ]
				}
				Object.keys( gender_hair ).forEach( gender => {
					if ( force_gender && gender != force_gender ) {
						return
					}
					let by_gender = []
					gender_hair[gender].forEach( hair => {
						const key = gender + ( hair ? ( "_" + hair ) : "" )
						const price = ( service[ key ] ? service[ key ][0] : null )
						if ( price ) {
							by_gender.push( price )
						}
					})
					if ( by_gender.length > 0 ) {
						all.push( by_gender.reduce( ( a, b ) => a ? comp( a, b ) : b ) )
					}
				})
				if ( all.length === 0 ) {
					return undefined
				}
				return all.reduce( ( a, b ) => a ? comp( a, b ) : b )
			},
			rangeOfService( service ) {
				let min = this.edgeOfService(service, Math.min)
				let max = this.edgeOfService(service, Math.max)
				if ( min === undefined ) {
					min = ""
				}
				if ( max === undefined ) {
					max = ""
				}
				if( max === min ) {
					return min + "€"
				}
				return min + "€ - " + max + "€"
			},
			edgeOfPackage( pack, comp ) {
				let f = pack.computedServices.reduce( ( total, serv ) => {
					const n = this.edgeOfService(serv, comp, "female")
					if ( n === undefined || total === undefined ) {
						return undefined
					}
					return total + parseInt(n)
				}, 0 )
				let m = pack.computedServices.reduce( ( total, serv ) => {
					const n = this.edgeOfService(serv, comp, "male")
					if ( n === undefined || total === undefined ) {
						return undefined
					}
					return total + parseInt(n)
				}, 0 )
				let c = pack.computedServices.reduce( ( total, serv ) => {
					const n = this.edgeOfService(serv, comp, "child")
					if ( n === undefined || total === undefined ) {
						return undefined
					}
					return total + parseInt(n)
				}, 0 )
				let values = []
				if ( f !== undefined ) {
					values.push( f )
				}
				if ( m !== undefined ) {
					values.push( m )
				}
				if ( c !== undefined ) {
					values.push( c )
				}
				if ( values.length == 0 ) {
					return "€"
				}
				return comp.apply( comp, values ) + "€"
			},
			rangeOfPackage( pack, comp ) {
				let min = this.edgeOfPackage( pack, Math.min )
				let max = this.edgeOfPackage( pack, Math.max )
				if( max === min ) {
					return min
				}
				return min + " - " + max
			},
			updateMainSchedule() {
				let mainSchedule = this.$store.state.schedules
				let result = []
				for ( let j = 0; j < 7; j++ ) {
					let sched = mainSchedule[j]
					let newValues = []
					if ( sched.morningop != "0" && sched.morningclos != "0" ) {
						newValues.push( "**" + this.hourNumber( sched.morningop ) + "** - " + this.hourNumber( sched.morningclos ) )
					}
					if ( sched.afternop != "0" && sched.afternclos != "0" ) {
						newValues.push( "**" + this.hourNumber( sched.afternop ) + "** - " + this.hourNumber( sched.afternclos ) )
					}
					result[j] = ( newValues.length > 0 ? ( " - " + newValues.join( " / " ) ) : undefined )
				}
				this.mainSchedule = result
			},
			hourString( hour ) {
				var h = Math.floor( hour / 60 )
				var m = hour - h * 60
				if ( h < 10 ) { h = "0" + h }
				if ( m < 10 ) { m = "0" + m }
				return h + ":" + m
			},
			hourNumber( str ) {
				if ( str ) {
					var matches = str.match( /(\d*):(\d*)/ )
					if ( matches != null ) {
						return this.hourString( parseInt( matches[1] ) * 60 + parseInt( matches[2] ) )
					}
				}
				return null
			},
			//		this.services.map(({ id }, i) => ({ id, priority: i }))
			async save( callback ) {
				let patch = {}
				let patchGeneralWorker = {}
				if ( this.mapMarker ) {
					patch.location = `SRID=4326;POINT(${this.mapMarker.lng} ${this.mapMarker.lat})`
				}
				if ( this.serviceprices != this.$store.state.salon.prices_mode ) {
					patch.prices_mode = this.serviceprices
				}
				if ( this.$store.state.domain == "coachs" ) {
					if ( this.description != this.$store.state.salon.description ) {
						patch.description = this.description
					}
					if ( this.scope != this.$store.state.salon.scope ) {
						patch.scope = this.scope
					}
					let where = Object.entries(this.where).filter( ([k,v]) => !!v ).map( ([k,v]) => k )
					if ( !where.isSame(this.$store.state.salon.where || []) ) {
						patch.where = where
					}
				}
				/*
				if ( this.visibleOnline != this.$store.state.salon.visible_online ) {
					patch.visible_online = this.visibleOnline
				}
				if ( this.preventOnline != this.$store.state.salon.prevent_online ) {
					patch.prevent_online = this.preventOnline
				}
				*/
				if ( this.contact.address1 != this.$store.state.salon.address1 ) {
					patch.address1 = this.contact.address1
				}
				if ( this.contact.address2 != this.$store.state.salon.address2 ) {
					patch.address2 = this.contact.address2
				}
				if ( this.contact.phone != this.$store.getters.getGeneralWorker().phone ) {
					patchGeneralWorker.phone = this.contact.phone
				}
				if ( this.contact.email != this.$store.getters.getGeneralWorker().email ) {
					patchGeneralWorker.email = this.contact.email
				}
				if ( !Object.values(this.contact.socials).every( a => Object.values(this.socialsBeforeEdit).find( b => b == a ) ) || !Object.values(this.socialsBeforeEdit).every( a => Object.values(this.contact.socials).find( b => b == a ) ) ) {
					patch.socials = Object.values(this.contact.socials)
				}
				console.log( patch.socials, this.contact.socials, this.socialsBeforeEdit )
				try {
					await this.carouselSave()
					await this.gallerySave()
					if ( Object.keys(patch).length > 0 ) {
						await this.$api.salon.patch( this.$store.state.salon.id, patch )
					}
					if ( Object.keys(patchGeneralWorker).length > 0 ) {
						await this.$api.worker.patch( this.$store.getters.getGeneralWorker().id, patchGeneralWorker )
					}
					if ( this.servicesChanges.length > 0 ) {
						await this.$api.services.patch( this.servicesChanges.map( ( en, id ) => { return { id: id, show_website: en } } ).filter( x => x !== undefined ) )
					}
					if ( this.formulasChanges.length > 0 ) {
						await this.$api.formulas.patch( this.formulasChanges.map( ( en, id ) => { return { id: id, show_website: en } } ).filter( x => x !== undefined ) )
					}
					callback( true )
					this.fetchImages()
					setTimeout( () => {
						this.showSave = false
					}, 1000 )
				} catch( error ) {
					console.log( error )
					callback( false, error.response.data.error )
					// TODO : test gallery re-sorting
				}
			},
			scroll() {
				if ( this.$refs.save && this.$refs.save.$el.getBoundingClientRect().bottom > window.innerHeight - document.getElementById( "bottom-mask" ).scrollHeight ) {
					this.showSaveHelper = true
				} else {
					this.showSaveHelper = false
				}
			},
			async fetchImages() {
				this.carouselImages = [ { id: -1, img: "" }, { id: -1, img: "" }, { id: -1, img: "" } ]
				this.galleryImages = []
				try {
					const response = await this.$api.salon.get( `${this.$store.state.salon.id}/carousel` )
					response.data.forEach( img => {
						if ( img.image && img.image.length > 0 ) {
							this.carouselImages[img.ordering] = {
								id: img.id,
								img: process.env.VUE_APP_API_ROOT + '/' + img.image
							}
						}
					})
					const response2 = await this.$api.salon.get( `${this.$store.state.salon.id}/gallery` )
					response2.data.forEach( img => {
						if ( img.image && img.image.length > 0 ) {
							this.galleryImages.push({ image: process.env.VUE_APP_API_ROOT + '/' + img.image, ordering: img.ordering })
						}
					})
					this.galleryImages = this.galleryImages.sort( ( a, b ) => a.ordering - b.ordering )
					this.$forceUpdate()
				} catch ( error ) {
					console.log( error )
				}
			},
			descriptionWatcher( content ) {
				if ( this.description != content ) {
					this.showSave = true
				}
				let shadow = document.createElement( "div" )
				shadow.innerHTML = content
				this.description = content
				this.description_length = ( shadow.textContent || shadow.innerText ).length
			},
			async instagramConnect( callback ) {
				try {
					const { data: { token: token } } = ( await this.$api.get( "/workers/state_token" ) )
					console.log( token )

					let finished = false
					const params = {
						client_id: "5955500421157275",
						redirect_uri: process.env.VUE_APP_API_ROOT + "/ig/auth",
						scope: "user_profile,user_media",
						response_type: "code",
						state: token,
					//	state: this.$store.state.domain + ":" + this.$store.state.worker.id
					}
					console.log("instagramConnect", params)

					if ( typeof cordova == "undefined" ) {
						const popup = window.open( "https://www.instagram.com/oauth/authorize?" + Object.entries(params).map( ([k,v]) => k + "=" + v ).join("&"), "Instagram" )
						const watcher = action => {
							if ( action.method == "POST" ) {
								finished = true
								this.$api.unwatch( `/ig/auth`, watcher )
								if ( action.data.error ) {
									callback( false )
								} else {
									callback( true )
								}
							}
						}
						this.$api.watch( `/ig/auth`, watcher )
						let popupTick = setInterval( () => {
							if ( popup.closed ) {
								clearInterval(popupTick)
								setTimeout( () => {
									if ( !finished ) {
										this.$api.unwatch( `/ig/auth`, watcher )
										callback( false )
									}
								}, 500 )
							}
						}, 500 )
					} else {
						const newwindow = cordova.InAppBrowser.open( "https://www.instagram.com/oauth/authorize?" + Object.entries(params).map( ([k,v]) => k + "=" + v ).join("&"), "Instagram" )
						newwindow.addEventListener( 'exit', ev => {
							console.log( "exit", ev )
							if ( !finished ) {
								callback( false )
							}
							finished = true
						})
						newwindow.addEventListener( 'loadstop', ev => {
							console.log( "loadstop", ev )
							if ( ev.url.indexOf( "https://www.instagram.com/oauth" ) < 0 ) {
								finished = true
								newwindow.close()
								if ( ev.url.indexOf( "/ig/auth" ) > 0 ) {
									callback( true )
								} else {
									callback( false )
								}
							}
						} )
						newwindow.addEventListener( 'loaderror', ev => {
							console.log( "loaderror", ev )
							finished = true
							newwindow.close()
							callback( false )
						} )
					}
				} catch( error ) {
					console.log( error )
				}
			}
		},
		mounted() {
			this.update()
			this.updateMainSchedule()
			this.fetchImages()
			this.$store.subscribe( ( mutation, state ) => {
				if ( mutation.type === "reloadSchedules" ) {
					this.updateMainSchedule()
				}
			})
			window.addEventListener( "scroll", () => this.scroll() )

			delete L.Icon.Default.prototype._getIconUrl
			L.Icon.Default.mergeOptions({
				iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
				iconUrl: require('leaflet/dist/images/marker-icon.png'),
				shadowUrl: require('leaflet/dist/images/marker-shadow.png')
			})

			this.$nextTick( async () => {
				this.$refs.map.mapObject.setView( [ 46.227638, 2.213749 ], 4 )
				/*
				this.$api.salons.get( { id: this.$store.state.salon.id, "{fields}": [ "ST_AsText(location)" ] } ).then( response => {
					const pos = response.data[0].st_astext.match( /point\(([0-9.]+) ([0-9.]+)\)/i )
					if ( pos && pos.length == 3 ) {
						this.mapMarkerBase = latLng( pos[2], pos[1] )
						this.mapMarker = this.mapMarkerBase
						this.$refs.map.mapObject.setView( this.mapMarker, 14 )
					}
				}).catch( error => {
					console.log( error )
				})
				*/
				try {
					const resp = await axios.get( "https://api-adresse.data.gouv.fr/search", { params: { q: this.contact.address1 + " " + this.contact.address2 } } )
					if ( resp.data ) {
						this.mapMarker = latLng( resp.data.features[0].geometry.coordinates[1], resp.data.features[0].geometry.coordinates[0] )
						this.$refs.map.mapObject.setView( this.mapMarker, 15 )
					}
				} catch( error ) {
					console.log( error )
				}

				this.$refs.description.$children[1].$watch( "content", this.descriptionWatcher )
			})

			this.$api.watch( `${this.$store.state.domain}/salons/${this.$store.state.salon.id}/carousel`, action => {
				if ( action.method == "POST" || action.method == "PATCH" ) {
					action.data.forEach( img => {
						this.carouselImages[img.ordering] = {
							id: img.id,
							img: process.env.VUE_APP_API_ROOT + '/' + img.image
						}
					})
				} else if ( action.method == "DELETE" ) {
					action.data.forEach( img => {
						const found = this.carouselImages.find( e => e.id == img.id )
						if ( found ) {
							found.img = ""
						}
					})
				}
				this.$forceUpdate()
			})

			this.$api.watch( `${this.$store.state.domain}/salons/${this.$store.state.salon.id}/gallery`, action => {
				console.log( `${this.$store.state.domain}/salons/${this.$store.state.salon.id}/gallery`, action )
			})
		},
		beforeDestroy() {
			window.removeEventListener( "scroll", () => this.scroll() )
			if ( this.$refs.description && this.$refs.description.$children[1] ) {
				this.$refs.description.$children[1].$unwatch( "content", this.descriptionWatcher )
			}
		}
	}
</script>
