<template>
  <label
    tabindex="0"
    class="toggle-switch"
    :class="{ disabled: disabled, before: placement === 'before', active: checked }"
    @keypress="press($event)"
  >
    <input
      ref="box"
      type="checkbox"
      :checked="value"
      :disabled="disabled"
      v-on:change="
        checked = $event.target.checked;
        $emit('change', $event.target.checked);
        $emit('input', $event.target.checked);
      "
    />
    <span
      class="slider round"
      :style="color ? { 'background-color': checked ? color : '' } : ''"
    />
    <span>
      <span><slot /></span>
    </span>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: null,
    },
    placement: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: this.value || false,
    };
  },
  methods: {
    press(ev) {},
    setChecked(v) {
      this.checked = v;
      this.$refs.box.checked = v;
    },
  },
};
</script>
