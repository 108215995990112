<template>
	<div class="invoice-box">
		<template v-if="bill">
			<table cellpadding="0" cellspacing="0">
				<tbody>
					<tr class="top">
						<td colspan="5">
							<table>
								<tbody>
									<tr>
										<td class="title">
											Espace-Temps
										</td>
										<td>
											<b><span class="smallcaps">Facture</span> : <span>#{{ bill.id }}</span></b>
											<br>
											<span class="smallcaps">date de facture</span> : <span>{{ $d(bill.date, "numericonlydate") }}</span>
											<br>
											<span class="smallcaps">date d'échéance</span> : <span>{{ $d(bill.date, "numericonlydate") }}</span>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr class="information">
						<td colspan="5">
							<table>
								<tbody>
									<tr>
										<td>
											<div class="smallcaps">{{ bill.receiver }}</div>
										</td>
										<td>
											<div class="smallcaps">{{ bill.sender }}<br>Identifiant : <b>{{ $store.state.salon.clientid }}</b></div>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
	<!--
					<tr class="heading">
					<td colspan="3">Méthode de Paiement</td>
					<td>Check #</td>
					</tr>

					<tr class="details">
					<td colspan="3">Check</td>
					<td>1000</td>
					</tr>
	-->
					<tr class="heading">
						<td>#</td>
						<td>Articles</td>
						<td>Prix Unitaire</td>
						<td>Quantité</td>
						<td>Montant {{ ( bill.currency || "" ).toUpperCase() }}</td>
					</tr>
					<tr class="item" v-for="( title, i ) in bill.titles" :key="'bill-item-' + i">
						<td><span class="text-center">{{ i + 1 }}</span></td>
						<td>
							<div class= "smallcaps">{{ title }}</div>
							<div><i>{{ bill.descriptions[i] }}</i></div>
						</td>
					<!--	<td>
							<div class= "smallcaps">Abonnement mensuel : Premium</div>
							<div><i> Gestion de salon - Application mobile <br> Options : Rendez-vous en ligne - Statistiques - Gestion de stocks <br></i></div>
							<div><i>500 SMS inclus (rappel) </i></div>
						</td> -->
						<td><span type="number">{{ bill.unitprices[i].toFixed(2) }}</span></td>
						<td><span type="number">{{ bill.quantities[i] }}</span></td>
						<td>{{ bill.prices[i].toFixed(2) }}</td>
					</tr>
				</tbody>
			</table>
			<div class="totaux">
				<div class="total subtot">
					<span>Sous-total</span><span class= "price">{{ ( parseFloat(bill.total) / 1.20 ).toFixed(2) }}</span>
				</div>
				<div v-if="bill.discount" class="total">
					<span>{{ bill.discount }}</span><span class= "price">{{ bill.discount_value }}</span>
				</div>
				<div class="total">
					<span>TVA (20%)</span><span class= "price">{{ ( parseFloat(bill.total) - parseFloat(bill.total) / 1.20 ).toFixed(2) }}</span>
				</div>
				<div class="total fin">
					<span>Total</span><span class="price">€ {{ bill.total.toFixed(2) }}</span>
				</div>
			</div>
			<div class= "thx"><i>Merci de votre confiance !</i></div>
			<div class= "bottom-bill">
				<div class="bottom"> Espace-Temps SAS - 16 bd Saint-Germain 75005 Paris - contact@espacetemps.io - 07 69 56 57 59 <br> SIRET 89167613200014 - TVA FR44891676132 - Capital Social 2000€ </div>
			</div>
		</template>
	</div>
</template>


<script>
	export default {
		props: [
			"bill",
		],
		components: {
		},
		data() {
			return {
			}
		}
	}
</script>

<style scoped lang="scss" src="../css/pages/bill.scss"/>
